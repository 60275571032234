@import '../../../defaults';

@import '~@anwalt.de/design-system/dist/css/components/navbar-tabs';

.anw-no-js {
  .tab-content .fade:not(.show) {
    display: block;
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 909px) {
  .anw-header-inner:has(.anw-divider-standard) {
    margin-top: -36px;
  }
  .anw-content-nav:has(.anw-divider-standard) {
    margin-top: 44px;
  }
}

.anw-divider {
  display: block;

  @mixin divider {
    border-left: 2px solid $body-color;
    display: inline-block;
    padding-left: 20px;
  }

  .anw-divider-mobile {
    display: block;
    margin: 11px auto 9px;
    width: 34px;
  }

  @media (min-width: 520px) {
    &.anw-divider-search {
      @include divider;
    }

    .anw-divider-mobile-search {
      display: none;
    }
  }

  @media (min-width: 910px) {
    &.anw-divider-standard {
      @include divider;
    }

    .anw-divider-mobile-standard {
      display: none;
    }
  }
}
